<template>
  <div @click="microsoftFlow">
    <MicrosoftLogo/>
  </div>
</template>
<script>
import MicrosoftLogo from '/src/components/App/UI/MicrosoftLogo.vue';

export default {
  components: {
    MicrosoftLogo,
  },
  methods: {
    microsoftFlow() {
      window.location.href = `${process.env.VUE_APP_API_BASE_URL}/auth/microsoft`;
    },
  },
};
</script>
