<template>
  <card class="p-3 col-lg-8 bg-secondary">
    <h1 class="page-header mb-lg-3 card-title">Sign in to your account</h1>
    <ValidationObserver v-slot="{ invalid, validate }" ref="formValidator">
      <form @submit.prevent="validate().then(submit)" class="needs-validation">
        <div class="row d-flex justify-content-center">
          <div class="mx-3">
            <div class="mb-4">
              <b-row>
                <b-col lg="12">
                  <base-input
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    v-model="email"
                    rules="required|email"
                    group
                    alternative
                  ></base-input>
                </b-col>
                <b-col lg="12">
                  <base-input
                    :type="show_password ? 'text' : 'password'"
                    placeholder="Password"
                    v-model="password"
                    rules="required"
                    name="password"
                    ref="password"
                    autocomplete="off"
                    group
                    alternative
                  >
                    <template slot="append">
                      <i @click="show_password = !show_password" class="eye-icon"
                         :class="show_password === true ? 'fas fa-eye-slash' : 'fas fa-eye'" aria-hidden="true"></i>
                    </template>
                  </base-input>
                </b-col>
              </b-row>
            </div>
            <div class="layout-button">
              <div class="form-common-errors small-text mb-2">
                {{ loginFormErrors }}
              </div>
              <button
                type="submit"
                class="btn btn-outline btn-primary btn-block font-weight-bold"
                :disabled="invalid"
              >Sign in
              </button>
            </div>
            <div class="microsoft-sign d-flex align-items-center flex-column">
              <p @click="microsoftSign = !microsoftSign">
                <u>{{
                    !microsoftSign
                      ? 'Sign in with Microsoft?'
                      : 'Hide Microsoft sign in'
                  }}</u>
              </p>
              <template v-if="microsoftSign">
                <microsoft-button />
              </template>
              <div class="options mt-3">
                <!-- <p class="font-weight-bold">
                  Don’t have an account?
                  <router-link :to="{ name: 'Register' }">Sign up</router-link>
                </p> -->
                <p>
                  Forgot your password?
                  <router-link :to="{ name: 'ResetPassword' }">Reset password</router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </card>
</template>
<script>
import {mapActions} from 'vuex';
import {LOGIN_REQUEST} from '/src/store/storeActions';
import MicrosoftButton from '@/components/App/User/MicrosoftButton/MicrosoftButton.vue';

export default {
  components: {
    MicrosoftButton,
  },
  data() {
    return {
      email: '',
      password: '',
      microsoftSign: false,
      validator: null,
      show_password: false,
    };
  },
  methods: {
    ...mapActions([
      LOGIN_REQUEST,
    ]),
    async submit() {
      const data = {
        currentRequest: 'login',
        requestData: {
          email: this.email,
          password: this.password,
        },
      };
      this.clearData();
      this.LOGIN_REQUEST(data);
    },
    clearData() {
      this.email = '';
      this.password = '';
      this.validator.reset();
    },
  },
  computed: {
    getFormErrors: function() {
      const errors = [];
      if (this.validator) {
        Object.entries(this.validator.errors).forEach(([key, value]) => {
          if (value.length > 0) {
            errors.push(...value);
          }
        });
      }
      return errors;
    },
    loginFormErrors: function() {
      let errMessage = '';
      const errors = this.getFormErrors;
      if (errors.length > 0) {
        errMessage =
          !this.email || !this.password ?
            'Please fill all required fields' :
            'Email or password is incorrect';
      }
      return errMessage;
    },
  },
  mounted: function() {
    this.validator = this.$refs.formValidator;
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/sass/lbd/mixins/_layout.scss";
@import "@/assets/sass/custom/_app-vars.scss";
.login-logo {
  width: 150px;
}

.grid {
  display: grid;
  height: fit-content;
}

.self-end {
  justify-self: end;
}

.layout-button {
  @include respond-to(sm) {
    width: 100% !important;
  }
  @include respond-to(xs) {
    width: 100% !important;
  }
}

.microsoft-sign {
  margin-top: 20px;
  cursor: pointer;
}

.eye-icon:hover {
  color: $primary-border-color;
}
</style>
